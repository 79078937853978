import cx from 'classnames';
import type { FunctionComponent, HTMLAttributes } from 'react';
import React from 'react';
import styles from './icon.module.scss';

export enum IconName {
  Add = 'icon-add',
  Alert = 'icon-alert',
  ArrowDown = 'icon-arrow-down',
  ArrowLeft = 'icon-arrow-left',
  ArrowRight = 'icon-arrow-right',
  ArrowUp = 'icon-arrow-up',
  Attachment = 'icon-attachment',
  Beer = 'icon-beer',
  Bell = 'icon-bell',
  BellNotification = 'icon-bell-notification',
  BookVisit = 'icon-book-visit',
  BookMark = 'icon-bookmark',
  Bottle = 'icon-bottle',
  Calendar = 'icon-calendar',
  CalendarConfirm = 'icon-calendar-confirm',
  Camera = 'icon-camera',
  Can = 'icon-can',
  CarlsbergSymbol = 'icon-carlsber-symbol',
  CarlsbergSymbolFill = 'icon-carlsber-symbol-fill',
  CarretDown = 'icon-carret-down',
  CarretLeft = 'icon-carret-left',
  CarretRight = 'icon-carret-right',
  CarretUp = 'icon-carret-up',
  Check = 'icon-check',
  CheckboxCheckFill = 'icon-checkbox-check-fill',
  CheckboxCheckSquareFill = 'icon-checkbox-check-square-fill',
  CheckboxCheck = 'icon-checkbox-check',
  CheckboxSomeSelected = 'icon-checkbox-some-selected',
  Checkbox = 'icon-checkbox',
  ChevronDown = 'icon-chevron-down',
  ChevronLeft = 'icon-chevron-left',
  ChevronRight = 'icon-chevron-right',
  ChevronUp = 'icon-chevron-up',
  Clock = 'icon-clock',
  ClockFill = 'icon-clock-fill',
  Clone = 'icon-clone',
  Compass = 'icon-compass',
  CompassFill = 'icon-compass-fill',
  Connected = 'icon-connected',
  Contract = 'icon-contract',
  Coupon = 'icon-coupon',
  CraftBeer = 'icon-craft-beer',
  Customer = 'icon-customer',
  Destination = 'icon-destination',
  DiagonalDownLeft = 'icon-diagonal-down-left',
  DiagonalDownRight = 'icon-diagonal-down-right',
  DiagonalUpLeft = 'icon-diagonal-up-left',
  DiagonalUpRight = 'icon-diagonal-up-right',
  Download = 'icon-download',
  Draughtmaster = 'icon-draughtmaster',
  Dropbox = 'icon-dropbox',
  Edit = 'icon-edit',
  Email = 'icon-email',
  Eye = 'icon-eye',
  EyeOutlined = 'icon-eye-outlined',
  Facebook = 'icon-facebook',
  Feedback = 'icon-feedback',
  Filters = 'icon-filters',
  Github = 'icon-github',
  GlobalGlobe = 'icon-global-globe',
  Global = 'icon-global',
  Goblet = 'icon-goblet',
  Grid = 'icon-grid',
  Headline = 'icon-headline',
  Heart = 'icon-heart',
  HeartFilled = 'icon-heart_filled',
  Help = 'icon-help',
  HideMenu = 'icon-hide-menu',
  History = 'icon-history',
  Home = 'icon-home',
  Hop = 'icon-hop',
  Image = 'icon-image',
  Information = 'icon-information',
  Instagram = 'icon-instagram',
  ItemCheck = 'icon-item-check',
  ItemCheckOpen = 'icon-item-check-open',
  ItemNoCheck = 'icon-item-nocheck',
  Keg = 'icon-keg',
  Library = 'icon-library',
  Linkedin = 'icon-linkedin',
  List = 'icon-list',
  Loading = 'icon-loading',
  Lock = 'icon-lock',
  LockOpen = 'icon-lock-open',
  Map = 'icon-map',
  Market = 'icon-market',
  MenuLeft = 'icon-menu-left',
  MenuRight = 'icon-menu-right',
  Menu = 'icon-menu',
  Minus = 'icon-minus',
  More = 'icon-more',
  Mug = 'icon-mug',
  MyProjects = 'icon-my-projects',
  MulptipleArrow = 'icon-multiple-arrow',
  // customercreation
  NewCustomers = 'icon-new-customers',
  NewProject = 'icon-new-project',
  NoCheck = 'icon-nocheck',
  Notepad = 'icon-notepad',
  Offline = 'icon-offline',
  Outlet = 'icon-outlet',
  Pack = 'icon-pack',
  Pallet = 'icon-pallet',
  Pencil = 'icon-pencil',
  PictureOfSuccess = 'icon-picture-of-success',
  Pilsner = 'icon-pilsner',
  PinActive = 'icon-pin-active',
  PinChurn = 'icon-pin-churn',
  PinClosed = 'icon-pin-closed',
  PinInactive = 'icon-pin-inactive',
  PinIndirect = 'icon-pin-indirect',
  PinLabeling = 'icon-pin-labeling',
  PinParked = 'icon-pin-parked',
  PinProspect = 'icon-pin-prospect',
  PinSeveral = 'icon-pin-several',
  PinDistributor = 'icon-pin-distributor',
  Pint = 'icon-pint',
  Plus = 'icon-plus',
  Powerbi = 'icon-powerbi',
  RadioButtonOff = 'icon-radiobutton-off',
  RadioButtonOn = 'icon-radiobutton-on',
  Refresh = 'icon-refresh',
  Restaurant = 'icon-restaurant',
  Retry = 'icon-retry',
  Salesrep = 'icon-salesrep',
  SearchCustomer = 'icon-search-customer',
  Search = 'icon-search',
  Settings = 'icon-settings',
  Share = 'icon-share',
  Shop = 'icon-shop',
  ShoppingCartNotification = 'icon-shopping-cart-notification',
  ShoppingCart = 'icon-shopping-cart',
  SignOut = 'icon-sign-out',
  Skype = 'icon-skype',
  StarFill = 'icon-star-fill',
  Star = 'icon-star',
  Survey = 'icon-survey',
  Sync = 'icon-sync',
  TapNew = 'icon-tap_new',
  TapManager = 'icon-tap-manager',
  TechnicalSupport = 'icon-technical-support',
  Transport = 'icon-transport',
  Trash = 'icon-trash',
  People = 'icon-people',
  OutletVisit = 'icon-outlet-visit',
  Event = 'icon-event',
  Hourglass = 'icon-Artboard',
  Chart = 'icon-chart'
}

export enum IconSize {
  MICRO = 'micro',
  TINY = 'tiny',
  SMALL = 'small',
  MEDIUM = 'medium',
  LARGE = 'large',
  HUGE = 'huge'
}

export enum IconColor {
  ALERT = 'alert',
  DEFAULT = 'default',
  DISABLED = 'disabled',
  ERROR = 'error',
  GRAY = 'gray',
  PRIMARY = 'primary',
  WHITE = 'white',
  SUPPORT = 'support'
}

export interface IconProps extends HTMLAttributes<HTMLSpanElement> {
  name: IconName;
  size?: IconSize;
  color?: IconColor;
  withPadding?: boolean;
  insideButton?: boolean;
  secondary?: boolean;
  alignCenter?: boolean;
  alignLeft?: boolean;
  alignRight?: boolean;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
  [key: string]: any;
}

const Icon: FunctionComponent<IconProps> = ({
  size = IconSize.MEDIUM,
  color = IconColor.DEFAULT,
  withPadding = false,
  insideButton = false,
  secondary = false,
  alignCenter = true,
  alignLeft = false,
  alignRight = false,
  disabled,
  name,
  className,
  onClick,
  ...rest
}) => (
  <div
    aria-hidden
    data-testid={name}
    className={cx(styles.wrapper, styles[size], styles[color], className, {
      [styles.padding]: !!withPadding,
      [styles.insideButton]: !!insideButton,
      [styles.secondary]: !!secondary,
      [styles.clickable]: !disabled && !!onClick,
      [styles.alignCenter]: alignCenter,
      [styles.alignCenter]: alignLeft,
      [styles.alignRight]: alignRight,
      [styles.disabled]: !!disabled
    })}
    {...(!disabled && { onClick })}
    {...rest}
  >
    <span className={cx(styles.icon, styles[name])} />
  </div>
);

export default Icon;
