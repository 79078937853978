import type { Dayjs } from 'dayjs';
import type { Action } from 'redux';

export enum PageVisitType {
  Home = 'home',
  Customer = 'customer',
  CustomerBookVisit = 'customer_book_visit',
  Surveys = 'surveys',
  Notes = 'notes',
  VisitHistory = 'visit_history',
  Search = 'search',
  Account = 'account',
  Agenda = 'agenda',
  BookVisit = 'book_visit',
  Other = 'other'
}

export interface PageMetadataTracking {
  event: string;
  page: {
    country?: string;
    environment?: string;
    language?: string;
    path?: string;
    title?: string;
    type?: PageVisitType;
    customerName?: string;
    customerId?: string;
    customerCity?: string;
    customerStatus?: string;
    customerChannel?: string;
    customerMission?: string;
    customerOutletDefinition?: string;
    customerSupport?: string;
    customerVolume?: string;
    customerInvestmentLevel?: string;
    customerSeason?: string;
    customerLastVisit?: string;
    customerCompletedVisits?: number;
  };
  user: {
    id: string;
    login: string;
  };
}

export interface ToggleMenuTracking {
  event: string;
  section: {
    id: string;
    type: string;
  };
}

export interface CheckInTracking {
  event: string;
  visit: {
    customerId: string;
    date: string | Dayjs;
    reason: string;
  };
}
export interface SurveyEvent {
  event: string;
  survey: {
    name: string;
  };
}
export interface CreateEventTracking {
  event: string;
  visit: {
    reason: string;
    frequency: string;
    notes: string;
    customer: string;
  };
}
export interface LinkClickTracking {
  event: string;
  navigation: {
    id?: string;
    type: string;
    url: string;
  };
}

export interface SimpleEvent {
  event: string;
}

export const trackingActions = {
  TRACK_PAGE_METADATA: '##tracking/PAGE_METADATA',
  PAGE_VISIT: '##tracking/PAGE_VISIT',
  TRACK_TOGGLE_MENU: '##tracking/TOGGLE_MENU',
  TRACK_CHECK_IN: '##tracking/CHECK_IN',
  TRACK_LOGIN: '##tracking/LOGIN',
  TRACK_ADD_NOTE: '##tracking/ADD_NOTE',
  TRACK_SURVEY: '##tracking/SURVEY',
  TRACK_LINK_CLICK: '##tracking/LINK_CLICK',
  TRACK_BOOK_VISIT: '##tracking/BOOK_VISIT'
};

export interface PageVisitAction extends Action {
  type: typeof trackingActions.PAGE_VISIT;
  payload: { pageType: PageVisitType; path: string; isVirtual: boolean; customerId?: string };
}

export interface TrackPageMetadataAction extends Action {
  type: typeof trackingActions.TRACK_PAGE_METADATA;
  payload: PageMetadataTracking;
}

export interface TrackToggleMenuAction extends Action {
  type: typeof trackingActions.TRACK_TOGGLE_MENU;
  payload: ToggleMenuTracking;
}

export interface TrackCheckInAction extends Action {
  type: typeof trackingActions.TRACK_CHECK_IN;
  payload: CheckInTracking;
}
export interface TrackLogInAction extends Action {
  type: typeof trackingActions.TRACK_LOGIN;
  payload: SimpleEvent;
}
export interface TrackAddNoteAction extends Action {
  type: typeof trackingActions.TRACK_ADD_NOTE;
  payload: SimpleEvent;
}
export interface TrackSurveyAction extends Action {
  type: typeof trackingActions.TRACK_SURVEY;
  payload: SurveyEvent;
}
export interface TrackLinkClickAction extends Action {
  type: typeof trackingActions.TRACK_SURVEY;
  payload: LinkClickTracking;
}

export interface TrackBookVisitAction extends Action {
  type: typeof trackingActions.TRACK_BOOK_VISIT;
  payload: CreateEventTracking;
}

const trackPageVisit = (
  pageType: PageVisitType,
  path: string,
  isVirtual = false,
  customerId?: string
): PageVisitAction => ({
  type: trackingActions.PAGE_VISIT,
  payload: { pageType, path, isVirtual, customerId }
});

const trackPageMetadata = (event: PageMetadataTracking): TrackPageMetadataAction => ({
  type: trackingActions.TRACK_PAGE_METADATA,
  payload: event
});

const trackToggleMenu = (event: ToggleMenuTracking): TrackToggleMenuAction => ({
  type: trackingActions.TRACK_TOGGLE_MENU,
  payload: event
});

const trackCustomerTabClick = (id: string): TrackToggleMenuAction => ({
  type: trackingActions.TRACK_TOGGLE_MENU,
  payload: {
    event: 'tabClick',
    section: {
      id,
      type: 'customer'
    }
  }
});

const trackCheckIn = (event: CheckInTracking): TrackCheckInAction => ({
  type: trackingActions.TRACK_CHECK_IN,
  payload: event
});

const trackLogin = (isLogout?: boolean): TrackLogInAction => ({
  type: trackingActions.TRACK_LOGIN,
  payload: {
    event: !!isLogout ? 'logout' : 'login'
  }
});

const trackAddNote = (): TrackAddNoteAction => ({
  type: trackingActions.TRACK_ADD_NOTE,
  payload: { event: 'addNote' }
});

const trackSurvey = (name: string): TrackSurveyAction => ({
  type: trackingActions.TRACK_SURVEY,
  payload: {
    event: 'saveSurvey',
    survey: {
      name
    }
  }
});

const trackLinkClick = (navigation: LinkClickTracking['navigation']): TrackLinkClickAction => ({
  type: trackingActions.TRACK_LINK_CLICK,
  payload: {
    event: 'linkClick',
    navigation
  }
});
export const trackCreateEvent = (event: CreateEventTracking): TrackBookVisitAction => ({
  type: trackingActions.TRACK_BOOK_VISIT,
  payload: event
});

export {
  trackAddNote,
  trackCheckIn,
  trackCustomerTabClick,
  trackLinkClick,
  trackLogin,
  trackPageMetadata,
  trackPageVisit,
  trackSurvey,
  trackToggleMenu
};
