import type { IconName } from 'components/Icon';
import type { NotificationType } from 'constants/const';

export enum NotificationColor {
  SUCCESS = 'success',
  ACTION = 'action',
  ALERT = 'alert',
  GRAY = 'gray',
  ERROR = 'error'
}
export default interface Notification {
  id: string;
  type: NotificationType;
  message: string;
  sticky?: boolean;
  icon?: IconName;
  color: NotificationColor;
}
