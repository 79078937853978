/* eslint-disable no-console */
const baseStyles =
  'background: #0ac39b;' +
  'border: 2px solid #0ac39b;' +
  'color: white;' +
  'font-size: 10px;' +
  'display: block;' +
  'text-align: center;' +
  'margin: 3px;' +
  'border-radius: 4px;' +
  'padding: 1px 3px;';

function cadilog(...args: any[]) {
  console.log('%cCADI', baseStyles, ...args);
}

function cadiwarn(...args: any[]) {
  console.log('%cCADI', baseStyles + 'border: 2px solid #fac42f;', ...args);
}

export { cadilog, cadiwarn };
