import type { GenericSelector } from 'constants/const';
import type Market from 'utils/schemas/market';
import type { FavouriteMode } from './types/catalog';

export interface ConfigurationRequest {
  soldTo: string;
  employeeId: string;
}

export interface Configurations {
  status: string;
  salesOrg: string;
  shiptos: Array<string>;
  showPrices: boolean;
  hasMRO: boolean;
}
export interface FacetValue {
  code: string;
  label: string;
  selected: boolean;
  count: number;
}
export interface Facet {
  code: string;
  label: string;
  values: FacetValue[];
}

export interface QueryParams {
  code: string;
  value: string;
}

export interface SortMode {
  code: string;
  label: string;
  selected: boolean;
}

export enum MRO {
  mandatory = 'MANDATORY',
  recommended = 'RECOMMENDED',
  optional = 'OPTIONAL',
  all = 'ALL'
}

export enum CampaignProductScenario {
  QUALIFY = 'QUALIFY',
  REWARD = 'REWARD'
}

export interface MROType {
  code: MRO;
  name: string;
}
export interface Product {
  backInStockDate?: string;
  basePrice?: number;
  employeeId?: string;
  entryNumber?: number;
  firstDeliveryDate?: string;
  fromPrice?: number;
  hasValidAssortment?: boolean;
  imageName: string;
  inBasket: number;
  inStock: boolean;
  isFavourite: boolean;
  isSRFavourite: boolean;
  mro?: MRO;
  name: string;
  onPromotion: boolean;
  onTradeQuotation?: boolean;
  preferredQuantity?: number;
  price?: number;
  productDeliveryUnitName: string;
  rejectionReason?: string;
  rewardInBasket?: number;
  sku: string;
  skuFE?: string;
  validFrom?: string;
  campaignProductScenario?: CampaignProductScenario;
}
export interface Catalog {
  status: string;
  totalPages: number;
  totalResults: number;
  currencyIsocode: string;
  productList: Product[];
  facets: Facet[];
  sortModes: SortMode[];
  queryParams: QueryParams[];
  suggestions: [];
}

export interface ProductRejection {
  showModal: boolean;
  cartEntry?: CartEntry;
}

export interface Attributes {
  key: string;
  name: [{ countryIsocode: string; value: string }];
  values: [{ countryIsocode: string; value: string }];
}
export interface ProductDataItem {
  attributes: Attributes[];
  backInStockDate?: string;
  brand: string;
  campaignID?: string;
  category: string;
  description: string;
  hasValidAssortment: boolean;
  fullSizeImageName: string;
  imageName: string;
  inBasket: number;
  inStock: boolean;
  isFavourite: boolean;
  isSRFavourite: boolean;
  mro?: MRO;
  name: string;
  notifyBackInStock: false;
  onPromotion: boolean;
  onTradeQuotation?: boolean;
  packType: string;
  price: {
    basePrice?: number;
    fromPrice?: number;
    price?: number;
  };
  priceSymbol: string;
  productDeliveryUnit: string;
  productDeliveryUnitName: string;
  relatedProducts: [];
  sku: string;
  validFrom?: string;
  volume: number;
}

export interface ProductData {
  status: string;
  currencyIsocode: string;
  skus: ProductDataItem[];
  priceSymbol: string;
}

export enum DeliveryDateType {
  STANDARD = 'STANDARD',
  RED_DAY = 'RED_DAY'
}

export interface DeliveryDate {
  cutoff: string;
  deliveryDate: string;
  type?: DeliveryDateType;
}

export interface DeliveryDateEntry {
  cutoff?: string;
  deliveryDate: string;
}

export interface DeliveryOption {
  code: string;
  cost: string;
  currencyIsoCode: string;
}
export interface DeliveryDates {
  status: string;
  deliveryDates: DeliveryDate[];
  deliveryOptions: DeliveryOption[];
}
export interface CartEntry {
  addFree?: boolean;
  allowFreeProduct?: boolean;
  appliedPromotionId?: string;
  atpConfirmedQuantity?: number;
  atpRequestedQuantity?: number;
  backInStockDate?: string;
  basePrice?: number;
  entryNumber?: number;
  giveAway: boolean;
  imageName: string;
  inBasket: number;
  inStock: boolean;
  isEditable: boolean;
  isFavourite: boolean;
  isFree?: boolean;
  isSavePending?: boolean;
  isSRFavourite: boolean;
  maxQuantity?: number;
  minQuantity?: number;
  mro?: MRO;
  name: string;
  onPromotion: boolean;
  origin?: string;
  packSize?: number;
  quantity: number;
  rejectionReason?: string;
  rejectionReasonName?: string;
  sku: string;
  skuFE: string;
  totalPrice?: number;
  unit: string;
}

export interface UpdateCartEntry {
  sku: string;
  entryNumber?: number;
  quantity?: number;
  freeQuantity?: number;
}

export interface CartTotals {
  totalCode: TotalsCode;
  totalAmount: number;
}

export enum OrderStatus {
  Accepted = 'Accepted',
  Blocked = 'Blocked',
  Cancelled = 'Cancelled',
  Created = 'Created',
  Delivered = 'Delivered',
  InPreparation = 'In Preparation',
  InvalidInput = 'Invalid Input',
  Invoiced = 'Invoiced',
  Received = 'Received',
  Shipped = 'Shipped',
  Processed = 'PROC',
  PartiallyProcessed = 'PAPR',
  CANC = 'CANC',
  ProcessingError = 'PROCESSING_ERROR'
}

export enum OrderStatusColor {
  DEFAULT = 'default',
  SUCCESS = 'success',
  ALERT = 'alert',
  ERROR = 'error',
  GRAY = 'gray'
}
export interface Cart {
  appliedPromotions?: FiredPromotion[];
  atpCheckStatus?: ATPCheckStatus;
  atpRemovedEntries?: CartEntry[];
  cartId?: string;
  currencyIsocode: string;
  customerId: string;
  entries: CartEntry[];
  firedPromotions?: FiredPromotion[];
  orderStatus?: OrderStatus;
  priceSymbol: string;
  rewardsAvailableTQ?: boolean;
  status: string;
  totalDistinctProducts?: number;
  totalProducts: number;
  totals: CartTotals[];
  userMarket?: Market;
  outOfStockEntries?: CartEntry[];
}
export interface CartResult {
  status: string;
  newPromotions?: string[];
  updatedPromotions?: string[];
  cart: Cart;
}
export interface OrderEntry {
  sku: string;
  quantity: number;
  basePrice: number;
  totalPrice: number;
  isFavourite: boolean;
  onPromotion: boolean;
  entryNumber?: number;
}

export interface RejectionReason {
  code: string;
  name: string;
}

export enum TotalsCode {
  subTotalAmount = 'subTotalAmount',
  promotionAmount = 'promotionAmount',
  deliveryCosts = 'deliveryCosts',
  emptiesCosts = 'emptiesCosts',
  invoiceCharges = 'invoiceCharges',
  taxCosts = 'taxCosts',
  totalAmount = 'totalAmount',
  totalWithoutTax = 'totalWithoutTax',
  otherTax = 'otherTax',
  //sweden
  listPrice = 'listPrice',
  adminFees = 'adminFees',
  contractDiscounts = 'contractDiscounts',
  //finland
  surchargesCost = 'surchargesCost',
  minOrderFee = 'minOrderFee',
  //denmark
  handlingFee = 'handlingFee',
  channelDiscounts = 'channelDiscounts',
  headerDiscounts = 'headerDiscounts',
  freightCosts = 'freightCosts',
  //norway
  halfTaxCosts = 'halfTaxCosts',
  freeGoods = 'freeGoods',
  // loyalty points
  totalLoyaltyPointsCollected = 'totalLoyaltyPointsCollected'
}

export interface Order {
  orderNumber: string;
  deliveryDate: Date;
  poReference: string;
  deliveryAddressUid: string;
  deliveryAddressFormatted: string;
  totalProducts: number;
  totalDistinctProducts: number;
  currencyIsocode: string;
  totals: CartTotals[];
  entries: OrderEntry[];
  soldTo: string;
}
export interface OrderResult {
  status: string;
  order: Order;
}

export interface EntryProduct {
  name: string;
  sku: string;
}
export interface OrderHistoryEntry {
  currencyIsocode: string;
  cutoffDate: string;
  deliveryDate: Date;
  entries?: EntryProduct[] | string[];
  orderChannelCode: string;
  orderDate: Date;
  orderNumber: string;
  placedBy: string;
  poReference: string;
  shipTo: string;
  status: OrderStatus;
  ukOrderStatus?: OrderStatus;
  totals?: CartTotals[];
  type: string;
}

export function isOrderEntriesOfStringArrayType(array: EntryProduct[] | string[]): array is string[] {
  return typeof array[0] === 'string';
}

export interface OrderHistory {
  status: string;
  entries: OrderHistoryEntry[];
}

export interface OrderDetailsEntry {
  allowFreeProduct: boolean;
  appliedPromotionId?: string;
  entryNumber: number;
  entryPrice: number;
  giveAway: boolean;
  imageName: string;
  inBasket: number;
  inStock: boolean;
  isFavourite: boolean;
  isEditable: boolean;
  mro?: MRO;
  onPromotion: boolean;
  price: number;
  productName: string;
  quantity: number;
  rejectionReason?: string;
  rejectionReasonName?: string;
  sku: string;
  unit: string;
}

export interface OrderFees {
  basePrice: string;
  deliveryCosts: string;
  discounts: string;
  empties: string;
  minimumFee: string;
  promotions: string;
  tax: string;
}

export interface DeliveryAddress {
  country: string;
  postalCode: string;
  streetName: string;
  town: string;
}
export interface OrderDetails {
  currencyIsocode: string;
  cutoffDate: string;
  deliveryAddress: DeliveryAddress;
  deliveryDate: Date;
  entries: OrderDetailsEntry[];
  exportError?: string;
  orderChannelCode: string;
  orderDate: Date;
  orderFees: OrderFees;
  orderNumber: string;
  orderStatus: OrderStatus;
  placedBy: string;
  orderCombination?: boolean;
  poReference?: string;
  priceSymbol: string;
  rejectionReason?: string;
  rejectionReasonName?: string;
  status: string;
  ukOrderStatus?: OrderStatus;
  totals: CartTotals[];
  type: string;
  contactEmail?: string;
  contactName?: string;
  notes?: string;
}

export enum EditOrderStep {
  Check = 'CHECKCHANGES',
  Confirm = 'CONFIRMCHANGES'
}

export enum ATPCheckStatus {
  Ok = 'OK',
  None = 'NONE',
  Full = 'FULL',
  Partial = 'PARTIAL'
}

export interface ATPMessage {
  sku: string;
  orderQuantity: string;
  confirmedQuantity: string;
  salesUnit: string;
  entryNumber: number;
}
export interface EditOrderEntry {
  entryNumber?: number;
  incrementQuantity?: number;
  quantity?: number;
  rejectionReason?: string;
  sku: string;
  skuFE: string;
  unit: string;
  freeQuantity?: number;
}

export interface EditOrderDetails {
  status: string;
  atpCheckStatus: ATPCheckStatus;
  atpMessages: ATPMessage[];
  entries: CartEntry[];
  totals: CartTotals[];
  currencyIsocode: string;
}

export interface UpdatedCartAndEditedEntries {
  cart: Cart;
  editedCartEntries: EditOrderEntry[];
}

export interface RewardEntry {
  sku: string;
  quantity: number;
}

export const OrderPriceSymbol = [
  { code: 'SEK', symbol: 'kr' },
  { code: 'NOK', symbol: 'kr' },
  { code: 'EUR', symbol: '€' },
  { code: 'DKK', symbol: 'kr' },
  { code: 'GBP', symbol: '£' }
];

export enum PromotionType {
  COMPLEX = 'COMPLEX',
  SIMPLE = 'SIMPLE'
}

export interface Promotion {
  id: string;
  campaignLink?: string;
  campaignType?: PromotionType;
  description: string;
  fromDate: string;
  name: string;
  toDate: string;
}

export interface PromotionResult {
  status: string;
  totalResults: number;
  totalPages: number;
  campaignList: Promotion[];
  facets: Facet[];
}

export interface Category {
  code: string;
  name: string;
}

export enum PromotionMode {
  QUALIFY = 'QUALIFY',
  REWARD = 'REWARD'
}

export enum FilterPromotionMode {
  NORMAL = 'NORMAL',
  PENDING = 'PENDING'
}

export interface FiredPromotion {
  id: string;
  name: string;
  maxRewardQuantity: number;
  reward?: RewardEntry[];
  qualifyingProducts?: Array<string>;
}

export interface RewardList {
  rewards: Product[];
  priceSymbol: string;
}

export enum OfflineCartStatus {
  SAVED = 'SAVED',
  SYNCING = 'SYNCING',
  SYNCED = 'SYNCED',
  NONE = 'NONE'
}

export interface Sku {
  sku: string;
  unit?: string;
}

export enum UkOrderNature {
  Transfer = 'T',
  Agreed = 'A'
}

export const UkOrderNatureOptions: GenericSelector[] = [
  { code: UkOrderNature.Transfer, name: 'transfer' },
  { code: UkOrderNature.Agreed, name: 'agreed' }
];

export enum FavouriteUpdateAction {
  add = 'ADD',
  remove = 'REMOVE',
  set = 'SET',
  clear = 'CLEAR',
  sort = 'SORT'
}

export interface FavouriteItem {
  list: string;
  totalProducts?: number;
  productInList?: boolean;
}

export interface FavouriteItemUpdate {
  sku: string;
  preferredQuantity?: number;
  position?: number;
}

export interface FavouritesList {
  favouriteLists?: FavouriteItem[];
  curatedLists?: FavouriteItem[];
  favouriteSRLists?: FavouriteItem[];
}

export interface FavouriteListSelected {
  name: string;
  isSalesRepFavourite: boolean;
  mode: FavouriteMode;
}
export interface ResultProducts {
  status: string;
  totalPages: number;
  totalResults: number;
  currencyIsocode: string;
  entries: Product[];
  facets: Facet[];
  sortModes: SortMode[];
}

export interface FavouritesUpdateRequest {
  customerId: string;
  selectedAction: FavouriteUpdateAction;
  list: string;
  favourites?: FavouriteItemUpdate[];
  newList?: string;
  selectedFavouriteListName?: string;
}

export interface Favourite {
  status: string;
  favouritesCount: number;
}

export enum UpdateFavouriteListActions {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  RENAME = 'RENAME'
}

export type FavouriteContext = 'favouriteSidebar' | 'catalog';

// Favourites state interfaces
export interface UpdateFavouriteListSuccessPayload {
  status: string;
  selectedAction: UpdateFavouriteListActions;
  favouriteName: string;
}

export enum DynamicFilterActions {
  OrderedProducts = 'ORDERED_PRODUCTS'
}

export enum DynamicFilterTypes {
  NotOrdered4Months = 'NOT_ORDERED_4_MONTHS'
}

// Selector definition
export interface DynamicFilterItem {
  code: DynamicFilterTypes;
  name: string;
}

// Error interfaces
export interface HybrisError {
  status: string;
  messages: HybrisErrorMessage[];
}

export interface HybrisErrorMessage {
  code: string;
  level: string;
  key: string;
  message: string;
}
