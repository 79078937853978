import { AppConfigManager } from 'config';
import { ENVIRONMENTS } from 'constants/const';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import ChainedBackend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LocizeBackend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';
import { isLocalhost } from 'utils/helpers';

const i18nInstance = i18n
  .use(ChainedBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  .use(initReactI18next);

const i18nConfig = async () => {
  const {
    environment,
    translations: {
      projectId,
      supportedLangs,
      defaultLang,
      fallbackLng,
      namespace: { defaultNamespace, namespaceList },
      version: { dev, prod }
    }
  } = AppConfigManager.get();

  const version = environment === ENVIRONMENTS.PROD ? prod : dev;

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  await i18nInstance.init({
    defaultNS: defaultNamespace,
    ns: namespaceList,
    // Loading only `en` from `en-UK`
    load: 'languageOnly',
    // Allows us to fallback from unsupported languages
    fallbackLng,
    // Only saving missing keys in Dev environment
    ...(isLocalhost() && { saveMissing: true }),
    interpolation: {
      escapeValue: false
    },
    react: {
      useSuspense: true
    },
    supportedLngs: supportedLangs,
    contextSeparator: '.',
    backend: {
      backends: [LocalStorageBackend, LocizeBackend],
      backendOptions: [
        {
          expirationTime: 24 * 60 * 60 * 1000 // 1 day
        },
        {
          projectId,
          referenceLng: defaultLang,
          allowedAddOrUpdateHosts: ['localhost', '0.0.0.0'],
          version,
          ...(isLocalhost() && { apiKey: __LOCIZE_API_KEY__ })
        }
      ]
    }
  });
};

export const getCurrentLanguage = () => {
  const {
    translations: { defaultLang }
  } = AppConfigManager.get();

  const storedLang = localStorage.getItem('i18nextLng');
  const langOnly = storedLang?.substr(0, storedLang.indexOf('-')) || storedLang;
  return langOnly || defaultLang;
};

export const initI18n = async () => {
  await i18nConfig();

  document.documentElement.lang = getCurrentLanguage();

  return i18nInstance;
};

export const clearTranslationCache = () => {
  Object.entries(localStorage)
    .map((x) => x[0])
    .filter((x) => x.startsWith('i18next_') || x === 'i18nextLng')
    .map((x) => localStorage.removeItem(x));
};

export const setupBasicI18nInstance = () => {
  i18n.use(initReactI18next).init({
    lng: 'en',
    fallbackLng: 'en',
    ns: ['default'],
    load: 'languageOnly',
    defaultNS: 'default',
    react: {
      useSuspense: false
    },
    resources: {
      en: {
        default: {}
      }
    }
  });
};

export default i18nInstance;
