import { eventProfileRoute } from 'modules/Events/utils/routes';
import { outletProfileRoute } from 'modules/Outlets/utils/routes';
import type {
  FitSurveyUrlParams,
  OutletSurveyUrlParams,
  OutletSurveysUrlParams,
  RegularSurveyUrlParams,
  RegularSurveysUrlParams,
  SurveySubmissionDetailsUrlParams,
  SurveySubmissionsHistoryUrlParams,
  SurveysHistoryUrlParams,
  SurveysSlugsParams
} from 'modules/Surveys/types';
import { injectPathParams } from 'utils/routes';

const surveysPaths = {
  fitSurvey: '/fit-survey',
  outletSurveys: '/outlet-surveys',
  outletSurvey: '/outlet-surveys/:surveyId',
  regularSurveys: '/regular-surveys',
  regularSurvey: '/regular-surveys/:surveyId',
  surveysHistory: '/surveys-history/:surveyType',
  surveySubmissionsHistory: '/surveys-history/:surveyType/:surveyId/submissions',
  surveySubmissionDetails: '/surveys-history/:surveyType/:surveyId/submissions/:submissionId'
};

export const fitSurveyRoute = {
  slugOnly: {
    path: surveysPaths.fitSurvey,
    url: surveysPaths.fitSurvey
  },
  eventProfileMatch: {
    path: eventProfileRoute.path + surveysPaths.fitSurvey,
    url: ({ eventId, id }: FitSurveyUrlParams) => eventProfileRoute.url({ eventId, id }) + surveysPaths.fitSurvey
  }
};

export const outletSurveysRoute = {
  slugOnly: {
    path: surveysPaths.outletSurveys,
    url: surveysPaths.outletSurveys
  },
  outletProfileMatch: {
    path: outletProfileRoute.path + surveysPaths.outletSurveys,
    url: ({ id }: OutletSurveysUrlParams) => outletProfileRoute.url({ id }) + surveysPaths.outletSurveys
  }
};

export const outletSurveyRoute = {
  slugOnly: {
    path: surveysPaths.outletSurvey.replace(surveysPaths.outletSurveys, ''),
    url: surveysPaths.outletSurvey.replace(surveysPaths.outletSurveys, '')
  },
  outletProfileMatch: {
    path: outletProfileRoute.path + surveysPaths.outletSurvey,
    url: ({ id, surveyId }: OutletSurveyUrlParams) =>
      outletProfileRoute.url({ id }) + injectPathParams(surveysPaths.outletSurvey, { surveyId })
  }
};

export const regularSurveysRoute = {
  slugOnly: {
    path: surveysPaths.regularSurveys,
    url: surveysPaths.regularSurveys
  },
  eventProfileMatch: {
    path: eventProfileRoute.path + surveysPaths.regularSurveys,
    url: ({ eventId, id }: RegularSurveysUrlParams) =>
      eventProfileRoute.url({ eventId, id }) + surveysPaths.regularSurveys
  }
};

export const regularSurveyRoute = {
  slugOnly: {
    path: surveysPaths.regularSurvey.replace(surveysPaths.regularSurveys, ''),
    url: surveysPaths.regularSurvey.replace(surveysPaths.regularSurveys, '')
  },
  eventProfileMatch: {
    path: eventProfileRoute.path + surveysPaths.regularSurvey,
    url: ({ eventId, id, surveyId }: RegularSurveyUrlParams) =>
      eventProfileRoute.url({ eventId, id }) + injectPathParams(surveysPaths.regularSurvey, { surveyId })
  }
};

export const surveysHistoryRoute = {
  slugOnly: {
    path: surveysPaths.surveysHistory,
    url: ({ surveyType }: SurveysSlugsParams['surveysHistory']) =>
      injectPathParams(surveysPaths.surveysHistory, { surveyType })
  },
  outletProfileMatch: {
    path: outletProfileRoute.path + surveysPaths.surveysHistory,
    url: ({ id, surveyType }: SurveysHistoryUrlParams) =>
      outletProfileRoute.url({ id }) + injectPathParams(surveysPaths.surveysHistory, { surveyType })
  }
};

export const surveySubmissionsHistoryRoute = {
  path: outletProfileRoute.path + surveysPaths.surveySubmissionsHistory,
  url: ({ id, surveyId, surveyType }: SurveySubmissionsHistoryUrlParams) =>
    outletProfileRoute.url({ id }) + injectPathParams(surveysPaths.surveySubmissionsHistory, { surveyId, surveyType })
};

export const surveySubmissionDetailsRoute = {
  path: outletProfileRoute.path + surveysPaths.surveySubmissionDetails,
  url: ({ id, submissionId, surveyId, surveyType }: SurveySubmissionDetailsUrlParams) =>
    outletProfileRoute.url({ id }) +
    injectPathParams(surveysPaths.surveySubmissionDetails, { submissionId, surveyId, surveyType })
};
