import { IconColor, IconSize } from '@carlsberggroup/malty.atoms.base-icon';
import { CarlsbergFilled } from '@carlsberggroup/malty.icons.carlsberg-filled';
import { AppConfigManager, CADI_CONFIG_CACHE_NAME, CADI_CONFIG_PREVIOUS_CACHE_NAME } from 'config';
import {
  CADI_KEYCLOAK_INIT,
  CADI_KEYCLOAK_TOKEN,
  CADI_KEYCLOAK__REFRESH_TOKEN,
  ERROR_MESSAGES,
  ORDER_HISTORY_FILTERS,
  ORDER_TYPE,
  OUTLET_LIST_FILTERS,
  PAGE_SIZE,
  PROSPECT_LIST_FILTERS,
  SortDirection,
  VISIT_TYPE_CLASSIFICATION
} from 'constants/const';
import { clear } from 'db';
import { clearTranslationCache } from 'i18n';
import { fitSurveyRoute, regularSurveysRoute } from 'modules/Surveys/utils/routes';
import type { FC, ReactElement, ReactNode } from 'react';
import React from 'react';
import { PageVisitType } from 'state/utils/tracking/actions';
import type { Customer } from 'utils/schemas';
import Market from 'utils/schemas/market';
import type { Outlet } from 'utils/schemas/outlet';
import type VisitType from 'utils/schemas/visitType';
import { v4 as uuid } from 'uuid';
import { allMaltyIcons } from './allMaltyIcons';
import type { Action } from './schemas/action';
import { DisplayContext } from './schemas/action';

export type Procedure = (...args: any[]) => void;

export type Options = {
  isImmediate: boolean;
};

type ReplaceableParams = { customerId: string; customerName?: string; eventId?: string };

function debounce<F extends Procedure>(
  func: F,
  waitMilliseconds = 50,
  options: Options = {
    isImmediate: false
  }
): F {
  let timeoutId: NodeJS.Timeout | undefined;

  return function (this: any, ...args: any[]) {
    const context = this;

    const doLater = function () {
      timeoutId = undefined;
      if (!options.isImmediate) {
        func.apply(context, args);
      }
    };

    const shouldCallNow = options.isImmediate && timeoutId === undefined;

    if (timeoutId !== undefined) {
      clearTimeout(timeoutId);
    }

    timeoutId = setTimeout(doLater, waitMilliseconds);

    if (shouldCallNow) {
      func.apply(context, args);
    }
  } as any;
}

function genId() {
  return (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();
}

function genUUID() {
  return uuid();
}

export function findByType<Props>(children: ReactNode, component: FC<Props>): ReactElement<Props> {
  const result: ReactElement<Props>[] = [];

  React.Children.forEach(children, (child) => {
    if (child && typeof child === 'object' && 'type' in child) {
      const reactElement = child as ReactElement<unknown, (() => ReactElement) & { displayName: string }>;
      const childDisplayName = reactElement.type.displayName || reactElement.type.name;
      if (childDisplayName === component.displayName) {
        result.push(child);
      }
    }
  });

  return result[0];
}

function validateTimeString(time: string) {
  return time.search(/^\d{2,3}:\d{2,3}$/) != -1;
}

function getAppURL() {
  const locationArr = window.location.href.split('/');
  return `${locationArr[0]}//${locationArr[2]}/`;
}

function injectParams(str: string, obj: Record<string, any>) {
  return Object.keys(obj).reduce((prevStr, key) => {
    return prevStr.replace(`{${key}}`, obj[key]);
  }, str);
}

function isObjEmpty(obj?: Record<string, any>) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) return false;
  }
  return true;
}

function stringCapitalize(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const getBreakpointFromCSS = (): string => {
  const body = document.querySelector('body');

  return body ? window.getComputedStyle(body, '::before').getPropertyValue('content').replace(/"/g, '') : 'B1';
};

const getMessageFromError = (error: Error) => {
  return ERROR_MESSAGES[error.message] || 'Unexpected error';
};

const getPageTypeFromPath = (path: string): { type: PageVisitType; customerId?: string } | null => {
  const pathArray = path.split('/');

  switch (pathArray[1]) {
    case 'customer':
      if (!pathArray[3]) {
        return null;
      }
      let type = PageVisitType.Customer;
      if (pathArray[3] === 'booking') {
        type = PageVisitType.CustomerBookVisit;
      } else if (pathArray[3] === 'notes') {
        type = PageVisitType.Notes;
      } else if (pathArray[3] === regularSurveysRoute.slugOnly.url.replace('/', '')) {
        type = PageVisitType.Surveys;
      } else if (pathArray[3] === 'history') {
        type = PageVisitType.VisitHistory;
      }
      return { type, customerId: pathArray[2] };

    case 'map':
      let pageType = PageVisitType.Home;
      if (!!pathArray[2]) {
        if (pathArray[2] === 'search') {
          pageType = PageVisitType.Search;
        } else if (pathArray[2] === 'account') {
          pageType = PageVisitType.Account;
        }
      }
      return { type: pageType };
    case 'agenda':
      return { type: PageVisitType.Agenda };
    default:
      return { type: PageVisitType.Other };
  }
};

export function getInitialType(visitTypes: VisitType[], market: Market, isNCFT?: boolean) {
  const {
    events: { defaultVisitTypes }
  } = AppConfigManager.get();

  const visitTypeClassification = isNCFT ? VISIT_TYPE_CLASSIFICATION.NCFT : VISIT_TYPE_CLASSIFICATION.REGULAR;
  const defaultMarketVisitTypeCode = defaultVisitTypes[market]?.[visitTypeClassification];

  return visitTypes.find(({ code }) => code === defaultMarketVisitTypeCode) ?? null;
}

function getTimezoneCodeFromMarket(market?: Market) {
  switch (market) {
    case Market.FI:
    case Market.FI_OFF_TRADE:
      return 'Europe/Helsinki';
    case Market.DE:
      return 'Europe/Berlin';
    case Market.FR:
      return 'Europe/Paris';
    case Market.DK:
    case Market.DK_OFF_TRADE:
      return 'Europe/Copenhagen';
    case Market.NO:
    case Market.NO_OFF_TRADE:
      return 'Europe/Oslo';
    case Market.SE:
    case Market.SE_OFF_TRADE:
    default:
      return 'Europe/Stockholm';
  }
}

function mapOrderChannelCode(code: string) {
  switch (code) {
    case 'Z01':
      return 'IC Call-inbound';
    case 'Z02':
      return 'IC Call-outbound';
    case 'Z03':
      return 'IC Customer fax';
    case 'Z04':
      return 'IC Customer e-mail';
    case 'Z05':
      return 'Mobile';
    case 'Z06':
      return 'Web';
    case 'Z07':
      return 'HHT';
    case 'Z08':
      return 'EDI';
    case 'Z09':
      return 'WebShop Mobile';
    case 'Z10':
      return 'CRM Back Office';
    case 'Z11':
      return 'E-commerce';
    case 'Z12':
      return 'EMD Online Orders';
    case 'Z13':
      return 'VMI Order';
    case 'Z14':
      return 'Order Third Party';
    case 'Z15':
      return 'Upselling';
    case 'Z16':
      return 'Cadi';
    default:
      return 'N/A';
  }
}

function marketHasFitSurvey(actions: Action[]): boolean {
  return actions.some((action: Action) => action.link === fitSurveyRoute.slugOnly.url);
}

const validateField = (value: string, pattern: RegExp) => {
  return pattern.test(value);
};

// Checks if data was stored less than 1 minute ago
const hasRecentData = (timestamp: number) => (new Date().getTime() - timestamp) / 1000 / 60 < 1;

const addressFor = (c: Customer | Outlet) => `${c.street}, ${c.postalCode} ${c.city}`;

const isLocalhost = () =>
  window.location.hostname === 'localhost' ||
  // [::1] is the IPv6 localhost address.
  window.location.hostname === '[::1]' ||
  window.location.hostname.match(/^0.0.0.0/) ||
  // 127.0.0.1/8 is considered localhost for IPv4.
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/);

export const getNavigatorLanguage = () => {
  return window.navigator.language || 'en-US';
};

const getNextPage = (page: number, pageSize: number, defaultPageSize: number = PAGE_SIZE.DEFAULT) =>
  pageSize > defaultPageSize ? pageSize / defaultPageSize : page + 1;

const ensureRange = (min: number, max: number, value: number) => Math.max(Math.min(value, max), min);

export const renameKey = (object: any, key: string, newKey: string) => {
  const clonedObj = Object.assign({}, object);
  const targetKey = clonedObj[key];
  delete clonedObj[key];
  clonedObj[newKey] = targetKey;
  return clonedObj;
};

export const parseOrderNumberAndShipTo = (
  orderNumberAndShipTo: string
): {
  orderNumber: string;
  shipTo?: string;
} => {
  const [orderNumber, shipTo] = orderNumberAndShipTo.split('-');
  return {
    orderNumber,
    shipTo
  };
};

export const removeObjectProperties = <Target extends Record<string, any>, Props extends Array<keyof Target>>(
  target: Target,
  props: Props
): Omit<Target, Props[number]> => {
  const clonedTarget = { ...target };
  for (let i = 0; i < props.length; i++) {
    if (clonedTarget.hasOwnProperty(props[i])) {
      delete clonedTarget[props[i]];
    }
  }
  return clonedTarget;
};

export function sortByDate<T>(array: T[], key: (item: T) => Date | string, direction: SortDirection) {
  if (direction === SortDirection.ASC) {
    return [...array].sort((a, b) => (key(a) > key(b) ? 1 : -1));
  }
  return [...array].sort((a, b) => (key(a) > key(b) ? -1 : 1));
}

export const gerOrderTypeName = (type: string) => {
  return ORDER_TYPE.find((orderType) => orderType.code === type)?.name;
};

const isParameterVulnerable = (parameter: string) => {
  return parameter === '__proto__' || parameter === 'constructor' || parameter === 'prototype';
};

export const isIdInvalid = (id: string) => {
  return isParameterVulnerable(id) || window.isNaN(Number(id));
};

export const isParameterInvalid = (parameter: string) => {
  return parameter === 'undefined' || isParameterVulnerable(parameter);
};

export const isAnyParameterInvalid = (parameters: string[]) => {
  return parameters.some(isParameterInvalid);
};

export const replaceLinkParameters = (link: string, parameters: ReplaceableParams) => {
  const encodedId = encodeURIComponent(parameters.customerId);
  const encodedCustomerName = encodeURIComponent(parameters.customerName || '');
  const encodedEventId = encodeURIComponent(parameters.eventId || '');
  return link
    .replace('{customerId}', encodedId)
    .replace('{customerName}', encodedCustomerName)
    .replace('{eventId}', encodedEventId);
};

export const clearLocalCache = () => {
  return clear().then(() => {
    clearTranslationCache();
    localStorage.removeItem(CADI_CONFIG_PREVIOUS_CACHE_NAME);
    localStorage.removeItem(CADI_CONFIG_CACHE_NAME);
    sessionStorage.removeItem(CADI_KEYCLOAK_INIT);
    sessionStorage.removeItem(CADI_KEYCLOAK_TOKEN);
    sessionStorage.removeItem(CADI_KEYCLOAK__REFRESH_TOKEN);
    sessionStorage.removeItem(OUTLET_LIST_FILTERS);
    sessionStorage.removeItem(PROSPECT_LIST_FILTERS);
    sessionStorage.removeItem(ORDER_HISTORY_FILTERS);
  });
};

enum ExternalLinkOptions {
  HTTP = 'http',
  HTTPS = 'https',
  MAIL = 'mailto',
  TEL = 'tel'
}

export const isExternalLink = (text: string) => {
  return (
    text.startsWith(ExternalLinkOptions.HTTP) ||
    text.startsWith(ExternalLinkOptions.HTTPS) ||
    text.startsWith(ExternalLinkOptions.TEL) ||
    text.startsWith(ExternalLinkOptions.MAIL)
  );
};

export const noop = () => null;

export const getEntryByDisplayContext = (displayContext: DisplayContext) => {
  return {
    [DisplayContext.OUTLET]: 'byOutletsIds' as const,
    [DisplayContext.EVENT]: 'byEventsIds' as const,
    [DisplayContext.PROSPECT]: 'byProspectsIds' as const
  }[displayContext];
};

type FindMatchingIconProps = {
  icon?: string;
  iconColor?: IconColor;
  iconSize?: IconSize;
  className?: string;
};

export const findMatchingIcon = ({
  icon,
  iconColor = IconColor.DigitalBlack,
  iconSize = IconSize.MediumSmall,
  className
}: FindMatchingIconProps) => {
  const IconComponent = !!icon ? allMaltyIcons[icon] : undefined;
  const props = { color: iconColor, size: iconSize, className };
  return IconComponent ? <IconComponent {...props} /> : <CarlsbergFilled {...props} />;
};

export const getObjectKeys = <T extends Record<string, any>>(target: T) => {
  const keys = Object.keys(target);
  const uniqueKeys = new Set(keys);

  const isKeyOfT = (value: string): value is Exclude<keyof T, symbol | number> => {
    return uniqueKeys.has(value);
  };

  return keys.filter(isKeyOfT);
};

export const toCamelCase = (target: string) => {
  return target.toLowerCase().replace(/[-_\s]+([a-zA-Z0-9])/g, (_, character) => character.toUpperCase());
};

export const areValuesPresentAndEqual = <Target,>(
  previous: Target | null | undefined,
  current: Target | null | undefined
): boolean => {
  return Boolean(previous && current && JSON.stringify(previous) === JSON.stringify(current));
};

export function isIn<T>(values: readonly T[], target: any): target is T {
  return values.includes(target);
}

export function isDefined<T>(target: T | undefined): target is T {
  return !!target;
}

export function sortByKey<T>(first: T, second: T, key: keyof T): number {
  if (first[key] < second[key]) {
    return -1;
  }
  if (first[key] > second[key]) {
    return 1;
  }

  return 0;
}

export {
  addressFor,
  debounce,
  ensureRange,
  genId,
  genUUID,
  getAppURL,
  getMessageFromError,
  getNextPage,
  getPageTypeFromPath,
  getTimezoneCodeFromMarket,
  hasRecentData,
  injectParams,
  isLocalhost,
  isObjEmpty,
  mapOrderChannelCode,
  marketHasFitSurvey,
  stringCapitalize,
  validateField,
  validateTimeString
};
