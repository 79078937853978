import { MaltyThemeProvider } from '@carlsberggroup/malty.theme.malty-theme-provider';
import { ErrorDisplay } from 'components/ErrorDisplay';
import { AppConfigManager } from 'config';
import { ENVIRONMENTS } from 'constants/const';
import React from 'react';
import { render } from 'react-dom';
import 'styles/index.scss';
import { cadilog } from 'utils/cadilog';
import { initDayjs } from 'utils/dayjs';
import { EndpointError } from 'utils/errors';
import { isLocalhost } from 'utils/helpers';
import { clearTranslationCache } from './i18n';
import * as serviceWorker from './serviceWorker';

const INITIALIZE_APP_ERROR_MESSAGE = 'Failed to initialize application';

const initServiceWorker = () => {
  if (!isLocalhost()) {
    const config = AppConfigManager.get();

    serviceWorker.register({
      onUpdate() {
        console.info('SW Cache Updated.');
        if (config.environment !== ENVIRONMENTS.DEV) {
          clearTranslationCache();
        }
        setTimeout(() => document.location.reload(), 1000);
      }
    });
  }
};

(async function initApp() {
  const rootElement = document.getElementById('root');

  try {
    initDayjs();
    await AppConfigManager.load();
    const { default: App } = await import('App');
    render(<App />, rootElement);
    cadilog(`App started, version: ${__VERSION__} (hash: ${__COMMIT_HASH__.replace('\n', '')})`);
    initServiceWorker();
  } catch (error) {
    cadilog(error);
    render(
      <MaltyThemeProvider theme="cadi">
        <ErrorDisplay message={error instanceof EndpointError ? error.message : INITIALIZE_APP_ERROR_MESSAGE} />
      </MaltyThemeProvider>,
      rootElement
    );
  }
})();
