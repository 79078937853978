enum Market {
  DE = 'DE_ON_TRADE',
  FR = 'FR_ON_TRADE',
  SE = 'SE_ON_TRADE',
  SE_OFF_TRADE = 'SE_OFF_TRADE',
  FI = 'FI_ON_TRADE',
  FI_OFF_TRADE = 'FI_OFF_TRADE',
  DK = 'DK_ON_TRADE',
  DK_OFF_TRADE = 'DK_OFF_TRADE',
  NO = 'NO_ON_TRADE',
  NO_OFF_TRADE = 'NO_OFF_TRADE',
  UK = 'UK_ON_TRADE'
}

export default Market;
