import dayjs from 'dayjs';
import { getConfig } from './api';
import type { AppConfigResponse, ConfigModel, EnrichedConfigModel, StoredAppConfig } from './types';
import { CADI_CONFIG_CACHE_NAME, NOT_STARTED_MANAGER_ERROR_MESSAGE } from './utils/consts';

export class AppConfigManager {
  static instance: AppConfigManager | null = null;
  #config: EnrichedConfigModel | null = null;

  constructor(configResponse: AppConfigResponse) {
    if (AppConfigManager.instance) {
      return AppConfigManager.instance;
    }

    const expiresIn = dayjs().add(1, 'day').format();
    const storedConfig: StoredAppConfig = { configResponse, expiresIn };
    localStorage.setItem(CADI_CONFIG_CACHE_NAME, JSON.stringify(storedConfig));
    this.#config = this.#enrichConfig(configResponse.data);
    AppConfigManager.instance = this;
  }

  #enrichConfig = (config: ConfigModel): EnrichedConfigModel => {
    const apiBaseURL = config.url;

    return {
      ...config,
      apiURL: apiBaseURL + config.apiEndpoint,
      orderingProxyURL: apiBaseURL + config.orderingProxyEndpoint,
      networkCheckURL: apiBaseURL + config.networkCheckEndpoint,
      orderingGraphqlURL: apiBaseURL + config.orderingGraphqlEndpoint,
      contentDeliveryURL: apiBaseURL + config.contentDeliveryEndpoint
    };
  };

  get config(): EnrichedConfigModel | null {
    return this.#config;
  }

  static get(): EnrichedConfigModel {
    if (!AppConfigManager.instance || !AppConfigManager.instance.config) {
      throw new Error(NOT_STARTED_MANAGER_ERROR_MESSAGE);
    }

    return AppConfigManager.instance.config;
  }

  static getStoredConfig(): StoredAppConfig | null {
    const storedConfig = localStorage.getItem(CADI_CONFIG_CACHE_NAME);

    return storedConfig ? JSON.parse(storedConfig) : null;
  }

  static isExpired() {
    const storedConfig = AppConfigManager.getStoredConfig();

    return !storedConfig || dayjs().isSameOrAfter(storedConfig.expiresIn);
  }

  static load(): Promise<AppConfigManager> {
    const storedConfig = AppConfigManager.getStoredConfig();

    if (storedConfig && !AppConfigManager.isExpired()) {
      const { configResponse } = storedConfig;

      return Promise.resolve(new AppConfigManager(configResponse));
    }

    return getConfig().then((configResponse) => {
      AppConfigManager.instance = null;

      return new AppConfigManager(configResponse);
    });
  }
}
