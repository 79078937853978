export interface BaseError<Details> extends Error {
  details?: Details;
}

interface Endpoint {
  endpoint: string;
  data?: unknown;
  params?: unknown;
  entityName?: string;
  status?: number;
}

export interface EndpointError<Details = unknown> extends BaseError<Details>, Endpoint {}

export class BaseError<Details> extends Error {
  constructor(message: string, details?: Details) {
    super(message);

    this.details = details;
  }
}

export class EndpointError<Details> extends BaseError<Details> {
  constructor(message: string, endpoint: Endpoint, details?: Details) {
    super(message, details);

    this.endpoint = endpoint.endpoint;
    this.data = endpoint.data;
    this.params = endpoint.params;
    this.entityName = endpoint.entityName;
    this.status = endpoint.status;
  }
}

export class MissingProviderError extends Error {
  constructor({ hook, provider }: { hook: string; provider: string }) {
    super(`"${hook}" must be used within "${provider}"`);
  }
}
