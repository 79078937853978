import { AppConfigManager } from 'config';
import type { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import arraySupport from 'dayjs/plugin/arraySupport';
import type { DurationUnitsObjectType } from 'dayjs/plugin/duration';
import duration from 'dayjs/plugin/duration';
import isBetween from 'dayjs/plugin/isBetween';
import isoWeek from 'dayjs/plugin/isoWeek';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import isToday from 'dayjs/plugin/isToday';
import localeData from 'dayjs/plugin/localeData';
import objectSupport from 'dayjs/plugin/objectSupport';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import type { DateRange, DaySelectorCode } from 'utils/schemas';

export const initDayjs = () => {
  dayjs.extend(weekOfYear);
  dayjs.extend(localeData);
  dayjs.extend(objectSupport);
  dayjs.extend(isSameOrAfter);
  dayjs.extend(isSameOrBefore);
  dayjs.extend(utc);
  dayjs.extend(weekday);
  dayjs.extend(advancedFormat);
  dayjs.extend(duration);
  dayjs.extend(relativeTime);
  dayjs.extend(arraySupport);
  dayjs.extend(isoWeek);
  dayjs.extend(isBetween);
  dayjs.extend(timezone);
  dayjs.extend(isToday);
};

export const setDayjsLocale = async (lang: string) => {
  let locale = lang;
  switch (lang) {
    case 'no':
    case 'nb':
    case 'nn':
      await import('dayjs/locale/nb.js');
      locale = 'nb';
      break;
    case 'da':
      await import('dayjs/locale/da.js');
      break;
    case 'de':
      await import('dayjs/locale/de.js');
      break;
    case 'fi':
      await import('dayjs/locale/fi.js');
      break;
    case 'fr':
      await import('dayjs/locale/fr.js');
      break;
    case 'sv':
      await import('dayjs/locale/sv.js');
      break;
    default:
      await import('dayjs/locale/en.js');
  }
  dayjs.locale(locale);
};

export const parseString = (date: string, timezone: string) => dayjs.tz(date, timezone);

export const formatDateByMarketTimezone = (date: Date | string, timezone: string) =>
  formatDate(new Date(date).toLocaleString('en-US', { timeZone: timezone }));

export const getLocaleStringByMarketTimezone = (date: string, timezone: string) =>
  dayjs(new Date(date).toLocaleString('en-US', { timeZone: timezone }));

export const formatDate = (date: Date | string | Dayjs) => {
  const { displayDateFormat } = AppConfigManager.get();
  const normalizedDate = dayjs.isDayjs(date) ? date : dayjs(date);

  return normalizedDate.format(displayDateFormat);
};

export const formatDateInLocal = (date: string) => {
  return formatDate(convertDateToLocal(date));
};

export const formatToComputedDateTime = (date?: Date | string | Dayjs) => {
  const { computedDateTimeFormat } = AppConfigManager.get();

  return dayjs(date).format(computedDateTimeFormat);
};

export const formatToComputedDate = (date?: Date | string | Dayjs) => {
  const { computedDateFormat } = AppConfigManager.get();

  return dayjs(date).format(computedDateFormat);
};

export const convertDateToLocal = (date: string) => dayjs.utc(date).local();

export const getStartOfTheDay = (date?: string | Dayjs) => dayjs(date).format('YYYY-MM-DDT00:00:00');

export function getRangeDate(day: DaySelectorCode): DateRange {
  const isPastDay = day < 0;
  const today = dayjs();
  return {
    startDate: (isPastDay ? today.subtract(Math.abs(day), 'd') : today).toISOString(),
    endDate: (isPastDay ? today : today.add(day, 'd')).toISOString()
  };
}

export function getRangeDateBetweenDays(startDay: DaySelectorCode, endDay: DaySelectorCode): DateRange {
  const today = dayjs();

  return {
    startDate: today.subtract(Math.abs(startDay), 'd').toISOString(),
    endDate: today.add(endDay, 'd').toISOString()
  };
}

export const formatTime = (date: string | Dayjs) => (typeof date === 'string' ? dayjs(date) : date).format('HH:mm');

const ONE_HOUR_IN_MINUTES = 60;

export const formatDuration = (minutes: number): string => {
  const fullHours = Math.floor(minutes / ONE_HOUR_IN_MINUTES);
  const leftMinutes = minutes % ONE_HOUR_IN_MINUTES;
  const duration: DurationUnitsObjectType = {};
  const durationFormat: string[] = [];
  if (fullHours > 0) {
    duration.hours = fullHours;
    durationFormat.push('H[h]');
  }
  if (leftMinutes > 0) {
    duration.minutes = leftMinutes;
    durationFormat.push('mm[m]');
  }
  return dayjs.duration(duration).format(durationFormat.join(' '));
};

export const isWeekDay = (date: Dayjs) => {
  const Weekdays = [1, 2, 3, 4, 5];
  return Weekdays.includes(date.day());
};

export function addBusinessDaysFromCurrent(number: number) {
  return addBusinessDaysFrom(dayjs(), number);
}

export function addBusinessDaysFrom(date: dayjs.Dayjs, number: number) {
  const direction = number < 0 ? -1 : 1;
  let daysRemaining = Math.abs(number);

  while (daysRemaining > 0) {
    date = date.add(direction, 'd');

    if (isWeekDay(date)) daysRemaining -= 1;
  }

  return date;
}

export const setCutOffDate = (date: dayjs.Dayjs) => {
  return addBusinessDaysFrom(date, -3).toISOString();
};

export const getIsBetweenInclusive = (dateToCompare: Dayjs, start: Dayjs, end: Dayjs) => {
  // List of all available units
  // The fourth parameter is about inclusivity. A [ indicates inclusion of a value. A ( indicates exclusion.
  // If the inclusivity parameter is used, both indicators must be passed.
  return dateToCompare.isBetween(start, end, null, '[]');
};

export const getUserTimezone = () => {
  return dayjs.tz.guess();
};
