import type { AppConfigResponse } from 'config/types';
import { FAILED_LOAD_CONFIGS_ERROR_MESSAGE } from 'config/utils/consts';
import { EndpointError } from 'utils/errors';

function throwEndpointError(details: Response | Error): never {
  throw new EndpointError(FAILED_LOAD_CONFIGS_ERROR_MESSAGE, { endpoint: __CONFIG__.appConfigURL }, details);
}

export async function getConfig(): Promise<AppConfigResponse> {
  try {
    const response = await fetch(__CONFIG__.appConfigURL);

    if (!response.ok) {
      throwEndpointError(response);
    }

    const parsedResponse: AppConfigResponse = await response.json();

    return parsedResponse;
  } catch (error) {
    if (error instanceof EndpointError) {
      throw error;
    }

    const safeError = error as Error;

    throwEndpointError(safeError);
  }
}
