import Empty from 'components/Empty';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type ErrorDisplayProps = {
  msgKey?: string;
  message?: string;
};

export const FALLBACK_ERROR_MESSAGE = 'Something went wrong';

export const ErrorDisplay = ({ msgKey, message }: ErrorDisplayProps) => {
  const { t } = useTranslation();

  return <Empty title={msgKey ? t(msgKey, message) : message ?? FALLBACK_ERROR_MESSAGE} shouldShowRecoverButton />;
};
