export enum Operator {
  EQUAL = 'equal',
  NOT_EQUAL = 'not equal',
  IN = 'in'
}

export enum DisplayContext {
  OUTLET = 'outlet',
  EVENT = 'event',
  PROSPECT = 'prospect'
}

export interface Filter {
  field: string;
  operator: Operator;
  queryValue: string;
}

export interface Action {
  id: string;
  display: {
    onOutletProfile: boolean;
    onVisitProfile: boolean;
    onProspectProfile: boolean;
  };
  icon: string;
  deepLinks: Action[];
  filters: Filter[];
  submenus: Action[];
  label: string;
  link: string;
  show: boolean;
  target: string;
  tradeOff: boolean;
  tradeOn: boolean;
  availableOffline: boolean;
}

export interface ActionApiResponse {
  meta: { status: number; redirect: null };
  views: {
    cadiActionsMenu?: {
      '@type': string;
      domain: string;
      items: Action[];
    };
  };
}
