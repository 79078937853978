import type { OutletProfileInfoUrlParams, OutletProfileUrlParams } from 'modules/Outlets/types';
import { injectPathParams } from 'utils/routes';

const outletsPaths = {
  index: '/outlets',
  map: '/map',
  outletProfile: '/customer/:id',
  profileInfo: '/customer/:id/customer-info'
};

export const outletsIndexRoute = {
  path: outletsPaths.index,
  url: outletsPaths.index
};

export const outletsMapRoute = {
  path: outletsPaths.map,
  url: outletsPaths.map
};

export const outletProfileRoute = {
  path: outletsPaths.outletProfile,
  url: ({ id }: OutletProfileUrlParams) => injectPathParams(outletsPaths.outletProfile, { id })
};

export const outletProfileInfoRoute = {
  slugOnly: {
    path: outletsPaths.profileInfo.replace(outletsPaths.outletProfile, ''),
    url: outletsPaths.profileInfo.replace(outletsPaths.outletProfile, '')
  },
  outletProfileMatch: {
    path: outletsPaths.profileInfo,
    url: ({ id }: OutletProfileInfoUrlParams) => injectPathParams(outletsPaths.profileInfo, { id })
  }
};
