import type { EventProfileUrlParams, EventsHistoryUrlParams, FitImageRecognitionUrlParams } from 'modules/Events/types';
import { outletProfileRoute } from 'modules/Outlets/utils/routes';
import { injectPathParams } from 'utils/routes';

const eventsPaths = {
  agenda: '/agenda',
  eventProfile: '/:id/events/:eventId',
  fitImageRecognition: '/:id/events/:eventId/fit-image-recognition',
  eventsHistory: '/history'
};

export const agendaRoute = {
  path: eventsPaths.agenda,
  url: eventsPaths.agenda
};

export const eventProfileRoute = {
  path: eventsPaths.eventProfile,
  url: ({ eventId, id }: EventProfileUrlParams) => injectPathParams(eventsPaths.eventProfile, { eventId, id })
};

export const eventsHistoryRoute = {
  slugOnly: {
    path: eventsPaths.eventsHistory,
    url: eventsPaths.eventsHistory
  },
  outletProfileMatch: {
    path: outletProfileRoute.path + eventsPaths.eventsHistory,
    url: ({ id }: EventsHistoryUrlParams) => outletProfileRoute.url({ id }) + eventsPaths.eventsHistory
  }
};

export const fitImageRecognitionRoute = {
  slugOnly: {
    path: eventsPaths.fitImageRecognition.replace(eventsPaths.eventProfile, ''),
    url: eventsPaths.fitImageRecognition.replace(eventsPaths.eventProfile, '')
  },
  eventProfileMatch: {
    path: eventsPaths.fitImageRecognition,
    url: ({ eventId, id }: FitImageRecognitionUrlParams) =>
      injectPathParams(eventsPaths.fitImageRecognition, { eventId, id })
  }
};
